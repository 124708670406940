import { useLocalization } from '@/hooks/useLocalization';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';
import { Discount as IconDiscount } from '../../../../components/icons';
import { Link } from '../../../../navigation';
import ProductList from '../../shop/components/commons/ProductList';
import useFindDiscountedProductsQuery from '../../shop/components/query/useFindDiscountedProductsQuery';
import useFilters from '../../shop/components/Shop/hooks/useFilters';

export default function ProductsSection() {
  const t = useTranslations('shop');

  const { hasActiveFilters } = useFilters();

  const { hash, deliveryType } = useLocalization();
  const { data, isLoading, isError } = useFindDiscountedProductsQuery({
    hash: hash || '',
    deliveryType,
    enabled: !hasActiveFilters,
  });

  const products = useMemo(() => {
    const items = data?.items || [];
    return items;
  }, [data]);

  if (hasActiveFilters || !data || data.items.length === 0) {
    return null;
  }

  return (
    <section className="tw-mt-20 tw-mb-20">
      <div className="tw-mx-auto xl:tw-w-[1170px] xl:tw-max-w-[1170px] tw-px-4 xl:tw-px-0">
        <div className="tw-flex sm:tw-items-center tw-justify-between tw-gap-1">
          <h2 className="tw-flex tw-items-center tw-gap-2 tw-text-3xl tw-text-greyscale-title tw-font-bold tw-tracking-tighter tw-leading-8 tw-m-0 tw-p-0">
            <IconDiscount className="tw-text-primary tw-hidden sm:tw-block" />
            {t('main.discountedTitle')}
          </h2>
          <Link
            href={{
              pathname: '/shop',
              query: { tag: 'angoloccasioni' },
            }}
            className="tw-text-greyscale-body tw-underline tw-text-base tw-font-medium tw-tracking-tight tw-cursor-pointer tw-whitespace-nowrap"
          >
            {t('filter.more')}
          </Link>
        </div>
        <ProductList
          columns={{
            xl: 4,
            lg: 3,
            md: 2,
            sm: 2,
            xs: 1,
          }}
          loading={isLoading}
          products={products}
        />
      </div>
    </section>
  );
}
