'use client';

import * as Targeting360 from '@/lib/targeting360';
import animateScrollTo from 'animated-scroll-to';
import { useEffect, useState } from 'react';
import CategorySection from './components/CategorySection';
import MainSection from './components/MainSection';
import TestimonialSection from './components/TestimonialSection';
import ExperienceSection from './components/ExperienceSection';
import ProductsSection from './components/ProductsSection';

interface Props {
  cities: { name: string }[];
  results?: any;
}

export default function HomeContent(props: Props) {
  useEffect(() => {
    Targeting360.trackHomePage();
  }, []);

  return (
    <>
      <MainSection />
      <CategorySection />
      <ProductsSection />
      <ExperienceSection />
      <TestimonialSection />
      <div id="targeting-360-div" />
    </>
  );
}
