import { Experience } from '@winelivery-org/wl-client-sdk-js/src/models/experience/experience';
import { useFormatter } from 'next-intl';
import { FC } from 'react';
import Image from 'next/image';

interface Props {
  experience: Experience;
  onClick?: (experience: Experience) => void;
}

const ExperienceGridItem: FC<Props> = (props) => {
  const format = useFormatter();
  return (
    <div
      className="tw-group tw-cursor-pointer tw-space-y-4"
      onClick={() => props.onClick && props.onClick(props.experience)}
    >
      <div className="tw-rounded tw-overflow-hidden tw-relative tw-h-56 tw-w-full">
        <Image
          fill style={{objectFit: 'cover'}}
          className="tw-transition tw-ease-in-out tw-duration-500 group-hover:tw-scale-125"
          src={props.experience.images![0]}
          alt={props.experience.title}
        />
      </div>
      <div className="tw-space-y-2">
        <div>
          <div className="tw-text-base tw-font-medium tw-tracking-tight tw-text-gray-800 tw-leading-6 group-hover:tw-underline">
            {props.experience.title}
          </div>
          <div className="tw-text-sm tw-text-gray-500">
            {props.experience.provider.name}
          </div>
        </div>
        <div className="tw-text-base tw-font-medium tw-text-gray-800">
          {format.number(props.experience.price, {
            style: 'currency',
            currency: 'EUR',
          })}
        </div>
      </div>
    </div>
  );
};

export default ExperienceGridItem;
