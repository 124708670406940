'use client';

import {useSearchParams} from 'next/navigation';
import {z} from 'zod';
import {useLocalization} from '../../../../../../hooks/useLocalization';
import {useRouter} from '../../../../../../navigation';
import {ProductFiltersSchema} from '../filters.dto';

export type ShopFilters = z.infer<typeof ProductFiltersSchema> & {
  hash: string;
  deliveryType: string;
};

export default function useFilters(): {
  filters: ShopFilters;
  hasActiveFilters: boolean;
  set: (filters: Partial<z.infer<typeof ProductFiltersSchema>>) => void;
  del: (...keys: (keyof z.infer<typeof ProductFiltersSchema>)[]) => void;
  reset: () => void;
} {
  const params = useSearchParams();
  const loc = useLocalization();
  const router = useRouter();

  const defaultParams = {
    page: 1,
    hash: loc.hash,
    deliveryType: loc.deliveryType || 'express',
  };

  function set(filters: z.infer<typeof ProductFiltersSchema>) {
    const newSearchParams = new URLSearchParams(params!);
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        newSearchParams.delete(key);
        value.forEach((v) => newSearchParams.append(key, v));
      } else {
        newSearchParams.set(key, value.toString());
      }
    }

    // Construct the query object from the search params
    const query = {};
    new Set([...newSearchParams.keys()]).forEach((key) => {
      query[key] =
        newSearchParams.getAll(key).length > 1
          ? [...new Set(newSearchParams.getAll(key))]
          : newSearchParams.get(key);
    });

    router.replace({pathname: '/shop', query}, {scroll: true});
  }

  function del(...keys: (keyof z.infer<typeof ProductFiltersSchema>)[]) {
    const newSearchParams = new URLSearchParams(params!);
    keys.forEach((key) => newSearchParams.delete(key));
    router.replace(
      {pathname: '/shop', query: Object.fromEntries(newSearchParams)},
      {scroll: true},
    );
  }

  function reset() {
    router.replace({pathname: '/shop'}, {scroll: true});
  }

  if (!params) {
    return {filters: defaultParams, hasActiveFilters: false, set, del, reset};
  }

  const parsed = ProductFiltersSchema.safeParse({
    q: params.get('q') || undefined,
    priceMax: params.get('priceMax') || undefined,
    priceMin: params.get('priceMin') || undefined,
    category: params.getAll('category') || undefined,
    subcategory: params.getAll('subcategory') || undefined,
    country: params.getAll('country') || undefined,
    region: params.getAll('region') || undefined,
    occasion: params.getAll('occasion') || undefined,
    characteristic: params.getAll('characteristic') || undefined,
    tag: params.getAll('tag') || undefined,
    sort: params.get('sort') || undefined,
    page: params.get('page') || undefined,
  });

  const hasActiveFilters = (filters: z.infer<typeof ProductFiltersSchema>) => {
    return (
      (filters.category || []).length +
        (filters.subcategory || []).length +
        (filters.country || []).length +
        (filters.region || []).length +
        (filters.characteristic || []).length +
        (filters.occasion || []).length +
        (filters.tag || []).length >
        0 ||
      !!filters.priceMin ||
      !!filters.priceMax
    );
  };

  const data = parsed.success
    ? {
        filters: {...defaultParams, ...parsed.data},
        hasActiveFilters: hasActiveFilters(parsed.data),
        set,
        del,
        reset,
      }
    : {filters: defaultParams, hasActiveFilters: false, set, del, reset};

  return data;
}
