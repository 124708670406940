'use client';

import Header from '../../../../components/header/Header';
import { useWindowScroll } from '../../../../hooks';
// import BannerDownloadApp from './BannerDownloadApp';

export default function HomeHeader() {
  const { isScrolledY } = useWindowScroll();
  return (
    <>
      {/* <BannerDownloadApp /> */}
      <Header
        className={
          !isScrolledY
            ? 'tw-bg-[#fef6f6]'
            : 'tw-border-b tw-border-greyscale-separator tw-bg-white'
        }
      />
    </>
  );
}
