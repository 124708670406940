import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
  highlightedCity?: string;
}

export default function MainSection(props: Props) {
  const t = useTranslations('index');

  return (
    <section className="tw-bg-greyscale-pink">
      <div className='xl:tw-w-[1170px] xl:tw-max-w-[1170px] tw-mx-auto tw-relative'>
        <div className="tw-grid lg:tw-grid-cols-2 tw-justify-center tw-items-center tw-gap-20">
          <div className='tw-flex tw-flex-col tw-px-4 xl:tw-px-0 tw-pt-8 xl:tw-pt-0'>
            <h1 className='tw-font-bold'>{t('MainSection.title')}</h1>
            <p className='tw-text-xl tw-font-medium'>{t('MainSection.sub-title')}</p>
            <Link
              href={`/produkte`}
              className="tw-m-0 tw-button tw-btn-primary tw-w-fit"
            >
              {t('MainSection.buy-now')}
            </Link>
          </div>
          <div className="tw-px-4 xl:tw-px-0 tw-flex tw-flex-col tw-gap-4">
            <Image
              src={`${IMGPW}/home-image.png`}
              alt="Hero home img"
              width={0}
              height={220}
              sizes="100vw"
              style={{width: 'auto', margin: '0 auto'}}
            />
          </div>
        </div>
      </div>
      <div className="tw-bg-wave tw-bg-repeat-x tw-aspect-[1440/190]" />
    </section>
  );
}
