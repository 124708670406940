export default function ProductItemSkeleton() {
  return (
    <div
      className={`
      tw-animate-pulse tw-flex tw-gap-3 md:tw-flex-col xs:tw-flex-row xs:tw-py-6
    `}
    >
      <div
        className={`
        tw-bg-greyscale-separator tw-rounded tw-shrink-0 md:tw-h-52 md:tw-w-full xs:tw-w-32 xs:tw-h-32
      `}
      />
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between">
        <div className="tw-space-y-0.5">
          <div className="tw-h-5 tw-bg-greyscale-separator tw-rounded" />
          <div className="tw-h-5 tw-w-4/5 tw-bg-greyscale-separator tw-rounded" />
          <div className="tw-h-4 tw-w-3/5 tw-bg-greyscale-separator tw-rounded" />
          <div className="tw-h-4 tw-w-2/5 tw-bg-greyscale-separator tw-rounded" />
        </div>
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-h-5 tw-w-12 tw-bg-greyscale-separator tw-rounded" />
          <div className="tw-rounded-full tw-bg-greyscale-separator tw-h-10 tw-w-10"></div>
        </div>
      </div>
    </div>
  );
}
