import {IMGPW} from '@/config';
import {Link} from '@/navigation';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

function CategoryCard(args: {
  category: string;
  label: string;
  description: string;
  picSrc: string;
}) {
  const t = useTranslations('index');
  return (
    <div>
      <div className="tw-relative">
        <Image
          src={`${IMGPW}/${args.picSrc}`}
          alt={args.label}
          width={0}
          height={0}
          className="tw-w-full tw-h-auto tw-rounded"
          sizes="100vw"
        />
      </div>
      <div className="tw-text-center tw-mt-4 tw-flex tw-flex-col tw-gap-1">
        <h4 className="tw-text-xl tw-font-bold tw-tracking-tight tw-text-greyscale-body tw-mb-0 tw-p-0">
          {args.label}
        </h4>
        <p className="tw-text-base tw-font-medium tw-tracking-tight tw-text-greyscale-label tw-mb-0 tw-p-0">
          {args.description}
        </p>
        <Link
          href={{pathname: '/shop', query: {category: args.category}}}
          className="tw-text-base tw-font-medium tw-tracking-tight"
        >
          {t('CategorySection.discover')}
        </Link>
      </div>
    </div>
  );
}

export default function CategorySection() {
  const t = useTranslations('index');

  return (
    <section className="tw-mt-20">
      <div className="tw-section-container">
        <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-greyscale-title tw-text-center tw-mb-4">
          {t('CategorySection.our-categories')}
        </h2>

        <div className="tw-flex tw-flex-wrap tw-gap-8 tw-justify-center tw-mb-20">
          <div className="sm:tw-w-1/4">
            <CategoryCard
              label={t('CategorySection.wine')}
              category="wine"
              description={t('CategorySection.wine-desc')}
              picSrc="categoria_vino_home.jpg"
            />
          </div>
          <div className="sm:tw-w-1/4">
            <CategoryCard
              label={t('CategorySection.drink')}
              category="drink"
              description={t('CategorySection.drink-desc')}
              picSrc="categoria_drink_home.jpg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
