import {useLocalization} from '@/hooks/useLocalization';
import {useRouter} from '@/navigation';
import {SearchProduct} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import {useFormatter, useTranslations} from 'next-intl';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {Button} from '../../../../../components/design-system';
import {Hearth as IconHearth, Plus} from '../../../../../components/icons';
import logger from '../../../../../logger';
import NoUserError from '../errors/NoUserError';
import useProduct from '../hooks/useProduct';
// import AddressModal from './AddressModal/AddressModal';
// import useAddressSearch from './AddressModal/hook';

const QuantityManager = dynamic(() => import('./QuantityManager'), {
  ssr: false,
});

interface Props {
  product: SearchProduct;
  onClick?: (product: SearchProduct) => void;
}

export default function ProductItem({product, onClick = () => null}: Props) {
  const t = useTranslations('common');
  const format = useFormatter();
  const router = useRouter();
  // const {location, deliveryType} = useLocalization();

  const {
    quantityInCart,
    isInWishList,
    addToCart,
    addToWishList,
    removeFromCart,
    removeFromWishList,
  } = useProduct(product);
  // const {updateGlobalLocation} = useAddressSearch('');

  function handleWishListClick() {
    try {
      if (isInWishList) {
        removeFromWishList();
      } else {
        addToWishList();
      }
    } catch (e) {
      if (e instanceof NoUserError) {
        router.push({
          pathname: `/shop/[id]`,
          params: {id: product.slug!},
        });
      }
      logger.error(e);
    }
  }

  const renderQuantityManager = () => {
    if (product.inStock === false) {
      return (
        <div
          className="tw-text-md tw-font-bold tw-leading-5 tw-truncate"
          style={{color: '#cf1c44'}}
        >
          {t('Availability.NotAvailable')}
        </div>
      );
    }

    // if (product.deliveryType === 'ecommerce') {
    //   return (
    //     <div
    //       className="tw-text-md tw-font-bold tw-leading-5 tw-text-right"
    //       style={{color: '#43d9a3'}}
    //     >
    //       {t('Availability.AvailableInShipping')}
    //     </div>
    //   );
    // }

    return (
      <QuantityManager
        quantity={quantityInCart}
        onAdd={addToCart}
        onSub={removeFromCart}
      />
    );
  };

  return (
    <div
      className={`
        tw-flex-row tw-py-6 sm:tw-flex-col tw-group tw-cursor-pointer tw-flex tw-gap-3 tw-h-full
      `}
      onClick={() => {
        if (onClick) {
          onClick(product);
        }
      }}
    >
      <div
        className={`
        xs:tw-border sm:tw-border-none xs:tw-border-greyscale-separator md:tw-border-transparent tw-relative tw-bg-white tw-rounded tw-overflow-hidden tw-shrink-0 tw-py-2
      `}
      >
        <div className="tw-relative sm:tw-h-52 sm:tw-w-full tw-w-32 tw-h-32">
          <Image
            fill
            className="tw-object-contain tw-transition tw-ease-in-out tw-duration-500 group-hover:tw-scale-125"
            src={product.imageUrl || ''}
            alt={product.name}
          />
        </div>
        <button
          className="tw-absolute tw-top-2 tw-right-2 tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-6 tw-bg-white tw-border tw-border-greyscale-separator tw-rounded-lg tw-text-secondary-dark hover:tw-shadow"
          onClick={(e) => {
            e.preventDefault();
            handleWishListClick();
          }}
        >
          <IconHearth
            className={`tw-h-4 ${
              isInWishList ? 'tw-fill-secondary-light' : ''
            }`}
          />
        </button>
      </div>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between">
        <div className="tw-space-y-0.5">
          <h3 className="tw-text-base tw-font-bold tw-tracking-tight tw-text-greyscale-body tw-m-0 tw-leading-5 group-hover:tw-underline">
            {product.name}
          </h3>
          <p className="tw-text-sm tw-text-greyscale-label tw-font-medium tw-tracking-tight">
            {product.producer}
          </p>
          <p className="tw-text-sm tw-text-greyscale-placeholder tw-font-medium tw-tracking-tight">
            {product.country ? product.country.name : ''}
            {product.region ? `, ${product.region.name}` : ''}
          </p>
        </div>
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-flex-col tw-text-base tw-tracking-tight tw-m-0 tw-gap-0 tw-leading-5">
            {!!product.discountedPrice && (
              <span className="tw-text-sm tw-line-through tw-text-greyscale-label tw-font-medium">
                {format.number(product.price, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </span>
            )}
            <span className="tw-text-greyscale-body tw-font-bold">
              {format.number(product.discountedPrice || product.price, {
                style: 'currency',
                currency: 'EUR',
              })}
            </span>
          </div>
          {
            renderQuantityManager()
          }
        </div>
      </div>
    </div>
  );
}
