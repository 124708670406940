'use client';

import { LocalizationContext } from '@/stores/localization/context';
import { LocalizationState } from '@/stores/localization/store';
import { useContext } from 'react';
import { useStore } from 'zustand';

export default function useLocalizationContext<T>(
  selector: (state: LocalizationState) => T,
): T {
  const store = useContext(LocalizationContext);

  if (!store) {
    throw new Error('Missing LocalizationContext.Provider in the tree');
  }

  return useStore(store, selector);
}
