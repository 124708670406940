import { z } from 'zod';

const FilterArraySchema = z.preprocess(
  (value) => (typeof value === 'string' ? [value] : value),
  z.array(z.string()).optional(),
);

export const ProductFiltersSchema = z.object({
  q: z
    .string()
    .optional()
    .transform((value) => (value ? decodeURIComponent(value) : undefined)),
  priceMax: z.coerce.number().optional(),
  priceMin: z.coerce.number().optional(),
  category: FilterArraySchema,
  subcategory: FilterArraySchema,
  country: FilterArraySchema,
  region: FilterArraySchema,
  occasion: FilterArraySchema,
  characteristic: FilterArraySchema,
  tag: FilterArraySchema,
  sort: z
    .enum([
      'rank:asc',
      'rank:desc',
      'name:asc',
      'name:desc',
      'price:asc',
      'price:desc',
    ])
    .optional(),
  page: z.coerce.number().optional().default(1),
});

export type ProductFiltersDto = z.infer<typeof ProductFiltersSchema>;
