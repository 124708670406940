import { useQuery } from '@tanstack/react-query';
import { GetSearchProductsQuery } from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import { getStoreProducts } from '../../../../../services/search';
import { GetSearchProductsResponse } from '@winelivery-org/wl-client-sdk-js/src/models/search/product';

type Filters = Pick<GetSearchProductsQuery, 'hash'> & {
  enabled?: boolean;
  deliveryType?: string;
};

export function getFindDiscountedProductsQueryOptions({
  enabled,
  ...rest
}: Filters) {
  const filters: Omit<GetSearchProductsQuery, 'deliveryType'> & {
    deliveryType?: string;
  } = {
    ...rest,
    limit: 4,
    tag: ['angoloccasioni'],
  };
  return {
    queryKey: ['products', filters],
    queryFn: () => getStoreProducts({...filters, deliveryType: "ecommerce"}),
    enabled,
  };
}

export default function useFindDiscountedProductsQuery(filters: Filters) {
  return useQuery<GetSearchProductsResponse>(
    getFindDiscountedProductsQueryOptions(filters),
  );
}
