import {SearchProduct} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import {Grid} from '../../../../../components/design-system';
import {Breakpointable} from '../../../../../components/design-system/types';
import {Link} from '../../../../../navigation';
import ProductItem from './ProductItem';
import ProductItemSkeleton from './ProductItemSkeleton';

interface Props {
  columns?: Breakpointable;
  // gap?: Breakpointable;
  loading?: boolean;
  products: SearchProduct[];
  hasNextPage?: boolean;
  onLoadMore?: () => void;
}

export default function ProductList({products, ...props}: Props) {
  return (
    <Grid
      {...props}
      gap={{xs: 0, sm: 8}}
      className="tw-divide-y tw-divide-greyscale-separator sm:tw-divide-none sm:tw-mt-0"
      data={products}
      keyExtractor={(product) => product.id}
      renderItem={(product) => (
        <Link
          key={product.id}
          href={{pathname: '/shop/[id]', params: {id: product.slug!}}}
          className="tw-no-underline tw-block tw-h-full"
        >
          <ProductItem product={product} />
        </Link>
      )}
      renderLoadingItem={() => <ProductItemSkeleton />}
    />
  );
}
