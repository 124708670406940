import { Experience } from '@winelivery-org/wl-client-sdk-js/src/models/experience/experience';
import { FC } from 'react';
import GridList, {
  Breakpoints,
} from '../../../../../components/design-system/GridList';
import { Link } from '../../../../../navigation';
import ExperienceGridItem from './ExperienceGridItem';

interface Props {
  breakpoints?: Breakpoints;
  columns?: number | 'auto';
  experiences: Experience[];
  scrollable?: boolean;
  title?: string;
}

const ExperienceGrid: FC<Props> = (
  props = { experiences: [], columns: 1, scrollable: false },
) => (
  <GridList
    breakpoints={props.breakpoints}
    columns={props.columns}
    scrollable={props.scrollable}
    title={props.title}
    data={props.experiences}
    keyExtractor={(item) => item.id}
    renderItem={(item) => {
      return (
        <Link
          href={{
            pathname: '/experience/experience-detail/[id]',
            params: { id: item.id },
          }}
          className="tw-no-underline"
        >
          <ExperienceGridItem experience={item} />
        </Link>
      );
    }}
  />
);

export default ExperienceGrid;
