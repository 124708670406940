import ScrollableList from '@/components/design-system/ScrollableList';
import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

export const BlockQuote = ({text, author}) => {
  return (
    <div>
      <blockquote className="tw-bg-white tw-shadow-xl">
        <p>{text}</p>
        <div>
          <h5>{author}</h5>
          <cite>@Facebook Review</cite>
        </div>
      </blockquote>
    </div>
  );
};

export default function TestimonialSection() {
  const t = useTranslations('index');

  const data: Array<{href: string; image: string}> = [
    {
      href: 'https://www.ilsole24ore.com/art/vino-domicilio-e-temperatura-giusta-startup-milanese-ora-sbarca-formentera-ACZWEgX',
      image: 'sole24ore.png',
    },
    {
      href: 'https://milano.corriere.it/notizie/cronaca/19_gennaio_07/vola-startup-winelivery-crowdfunding-11-milioni-nuove-aperture-e083ed90-128e-11e9-8e32-62f2e5130e0b.shtml',
      image: 'corriere.png',
    },
    {
      href: 'https://startupitalia.eu/103082-20190111-winelivery-raccoglie-piu-1-1-milioni-euro-crowdfundme',
      image: 'startup_italia.png',
    },
    {
      href: 'http://www.ansa.it/canale_terraegusto/notizie/vino/2019/01/22/vino-winelivery-raccoglie-12-milioni-da-crowdfunding_4fb4ba53-a01d-4eb9-9bb9-4c04a876f9c9.html',
      image: 'Ansa.png',
    },
    {
      href: 'http://vino.tv/it/winelivery-per-avere-il-vino-giusto-alla-temperatura-giusta-al-momento-giusto/',
      image: 'vinotv.png',
    },
    {
      href: 'https://ricerca.repubblica.it/repubblica/archivio/repubblica/2019/01/14/winelivery-raccoglie-altri-1-2-milioniAffari_e_Finanza23.html',
      image: 'laRepubblica.png',
    },
    {
      href: 'https://www.leggo.it/economia/news/winelivery_crowfunding-4217293.html',
      image: 'leggo.png',
    },
    {
      href: 'https://www.ilgiorno.it/milano/economia/winelivery-vino-domicilio-1.4674490',
      image: 'Ilgiorno.png',
    },
    {
      href: 'https://www.quotidiano.net/speciali/divino-sapore/winelivery-start-up-1.4655879',
      image: 'quotidiano.png',
    },
  ];

  return (
    <section className="tw-bg-white tw-py-20">
      <div className="tw-mx-auto xl:tw-w-[1170px] xl:tw-max-w-[1170px] tw-px-4 xl:tw-px-0">
        <div className="tw-flex tw-flex-wrap md:tw-flex-row tw-flex-col tw-gap-8 tw-items-center">
          <div className="md:tw-w-1/3 tw-flex-1">
            <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-greyscale-title tw-text-center md:tw-text-left">
              {t('TestimonialSection.h4')}
            </h2>
          </div>
          <div className="md:tw-w-2/3 tw-flex-2">
            <div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-8 tw-px-4 xl:tw-px-">
              <BlockQuote
                text={
                  'Eine fantastische Idee für jede Gelegenheit: von einem Gourmet-Dinner mit Freunden bis zu einem guten Bier vor dem Fernseher. Sehr große Auswahl an Weinen, Champagner und Craft-Bieren, anspruchsvolle Etiketten und bekannte Marken. Unbedingt probieren: meine Bestellung kam in 20 Minuten, frisches Bier zum richtigen Zeitpunkt. Prost!'
                }
                author={'VALERIO F.'}
              />
              <BlockQuote
                text={
                  'Sehr zufrieden! Die beste App, um in nur 30 Minuten etwas Gutes zu trinken! Ganz zu schweigen von den Cocktail-Kits, die man für eine gelungene Hausparty braucht! =)'
                }
                author={'GIANMARCO M.'}
              />
              <BlockQuote
                text={
                  'Sehnen Sie sich nach einer Flasche Wein, wenn das Abendessen fast fertig ist, entdecken Sie die Existenz dieser App, bestellen Sie und in 20 Minuten erhalten Sie zu Hause die gewünschte Flasche...hurviva!'
                }
                author={'MARILIA S.'}
              />
              <BlockQuote
                text={
                  'Ich habe im August während meines Mailand-Urlaubs Weinlackierungen und ihren Service verwendet und sie haben meine Nacht gerettet! Wir bestellten Cocktails und sie kamen direkt zu unserem airbnb Platz in zwanzig Minuten mit Eiswürfel, Gläser und Strohhalme! Toller Service!'
                }
                author={'OLIVER G.'}
              />
            </div>
          </div>
        </div>

        <div className="tw-text-center tw-mt-12">
          <h4 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-greyscale-body tw-mb-4 tw-p-0">
            {t('TestimonialSection.parlano-di-noi')}
          </h4>
          <ScrollableList
            autoPlay
            columns={{
              xxl: 5,
              xl: 5,
              lg: 4,
              md: 3,
              sm: 2,
              xs: 'auto',
            }}
            gap={8}
            data={data}
            keyExtractor={(elem, index) => index.toString()}
            renderItem={(elem) => (
              <a target="_blank" href={elem.href}>
                <Image
                  src={`${IMGPW + '/' + elem.image}`}
                  alt={elem.image}
                  width={110}
                  height={110}
                  className="opacity-75 m-auto"
                />
              </a>
            )}
          />
        </div>
      </div>
    </section>
  );
}
