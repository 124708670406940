import {
  CheckoutInput,
  CheckoutResponse,
  CompleteCheckoutInput,
  CompleteCheckoutResponse,
} from '@winelivery-org/wl-client-sdk-js/dist/models/experience/checkout';
import {ExperiencesFacets} from '@winelivery-org/wl-client-sdk-js/dist/models/experience/facets';
import {Home} from '@winelivery-org/wl-client-sdk-js/dist/models/experience/home';
import {
  Order,
  OrderFilters,
  Orders,
} from '@winelivery-org/wl-client-sdk-js/dist/models/experience/order';
import {Availability} from '@winelivery-org/wl-client-sdk-js/src/models/experience/divinea';
import {
  Experience,
  ExperienceFilters,
  GetExperiencesResponse,
} from '@winelivery-org/wl-client-sdk-js/src/models/experience/experience';
import {Provider} from '@winelivery-org/wl-client-sdk-js/src/models/experience/provider';
import axios from 'axios';
import {getApisRoot, getHeaders} from 'src/services/common';

const baseUrl = getApisRoot('wl-experience-service', 'experience');

export const getExperiencesFacets = async () => {
  const response = await axios.get<ExperiencesFacets>(
    `${baseUrl}/v1/experiences/facets`,
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getHome = async () => {
  const response = await axios.get<Home>(`${baseUrl}/v1/home`, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getExperienceById = async (id: string) => {
  const response = await axios.get<Experience>(
    `${baseUrl}/v1/experiences/${id}`,
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getAvailabilities = async (id: string) => {
  const response = await axios.get<Availability[]>(
    `${baseUrl}/v1/experiences/${id}/availabilities`,
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getProviderById = async (id: string) => {
  const response = await axios.get<Provider[]>(
    `${baseUrl}/v1/providers/${id}`,
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const getExperiences = async (filters?: ExperienceFilters) => {
  const response = await axios.get<GetExperiencesResponse>(
    `${baseUrl}/v1/experiences/`,
    {
      headers: await getHeaders(),
      params: filters,
    },
  );
  return response.data;
};

export const createCheckout = async (data: CheckoutInput) => {
  const response = await axios.post<CheckoutResponse>(
    `${baseUrl}/v1/checkout`,
    data,
    {
      headers: await getHeaders(),
    },
  );
  return response.data;
};

export const completeCheckout = async (
  checkoutId: string,
  data?: CompleteCheckoutInput,
) => {
  const response = await axios.post<CompleteCheckoutResponse>(
    `${baseUrl}/v1/checkout/${checkoutId}/complete`,
    data,
    {headers: await getHeaders()},
  );
  return response.data;
};

export const updateCheckout = async (
  checkoutId: string,
  data: CheckoutInput,
) => {
  const response = await axios.put<CheckoutResponse>(
    `${baseUrl}/v1/checkout/${checkoutId}`,
    data,
    {headers: await getHeaders()},
  );
  return response.data;
};

export const getOrderById = async (id: string) => {
  const response = await axios.get<Order>(`${baseUrl}/v1/orders/${id}`, {
    headers: await getHeaders(),
  });
  return response.data;
};

export const getUserOrders = async (filters?: OrderFilters) => {
  const response = await axios.get<Orders>(`${baseUrl}/v1/orders`, {
    headers: await getHeaders(),
    params: filters,
  });
  return response.data;
};
