import {useEffect, useState} from 'react';
import {IMGPW} from '@/config';
import {useTranslations} from 'next-intl';
import Link from 'next/link';
import ExperienceGrid from '../../experience/components/commons/ExperienceGrid';
import {getExperiences} from '../../../../services/experience';
import {Experience} from '@winelivery-org/wl-client-sdk-js/src/models/experience/experience';

export default function ExperienceSection() {
  const t = useTranslations('index');

  const [experiences, setExperiences] = useState<Experience[]>([]);

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    const result = await getExperiences();
    setExperiences(result.experiences);
  };

  return (
    <section className="tw-bg-greyscale-gray-lighter tw-py-20">
      <div className="tw-mx-auto xl:tw-w-[1170px] xl:tw-max-w-[1170px] tw-px-4 xl:tw-px-0">
        <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-tracking-tighter tw-mb-6 tw-text-greyscale-title text-center">{t('ExperienceSection.title')}</h2>
        <div className="tw-bg-white tw-mb-20">
          <div className="tw-flex tw-flex-wrap md:tw-flex-row tw-flex-col-reverse">
            <div className="md:tw-w-1/2 tw-flex-1">
              <div className="tw-py-4 tw-px-6">
                <h4 className="tw-text-xl tw-font-bold tw-tracking-tight tw-text-greyscale-body tw-mb-2 tw-p-0">
                  {t('ExperienceSection.sparkling.title')}
                </h4>
                <p className="tw-text-base tw-font-medium tw-tracking-tight tw-text-greyscale-label tw-mb-2 tw-p-0">
                  {t('ExperienceSection.sparkling.desc')}
                </p>
                <Link
                  href={{
                    pathname: '/shop',
                    query: {category: 'wine', subcategory: 'sparkling'},
                  }}
                  className="tw-m-0 tw-button tw-btn-primary"
                >
                  {t('ExperienceSection.sparkling.cta')}
                </Link>
              </div>
            </div>
            <div
              className="md:tw-w-1/2 tw-flex-1"
              style={{ minHeight: 150 }}
            >
              <div
                className="tw-bg-no-repeat tw-bg-center tw-bg-cover tw-h-[100%] tw-min-h-[150px]"
                style={{
                  backgroundImage: `url(${IMGPW}/banner_cold_home.jpg)`,
                }}
              />
            </div>
          </div>
        </div>
        {experiences.length > 0 ? (
          <section className="tw-container tw-mx-auto tw-px-4 lg:tw-px-6 xl:tw-px-8 home-swiper">
            <ExperienceGrid
              experiences={experiences || []}
              breakpoints={{
                xl: 4,
                md: 3,
                sm: 2,
              }}
              columns="auto"
              scrollable
              title={t('ExperienceSection.topExperienceTitle')}
            />
          </section>
        ) : null}
      </div>
    </section>
  );
}
