import {
  GetSearchProductsFacetsResponse,
  GetSearchProductsQuery,
  GetSearchProductsResponse,
  SearchProduct,
  SearchSearchProductsQuery,
  SearchSearchProductsResponse,
} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import axios from 'axios';
import { getApisRoot, getHeaders } from '../common';

const baseUrl = getApisRoot('wl-search-service', 'search');

export const getStoreProducts = async (
  params: Omit<GetSearchProductsQuery, 'deliveryType'> & {
    deliveryType?: string;
  },
) => {
  const response = await axios.get<GetSearchProductsResponse>(
    `${baseUrl}/v1/products`,
    {
      headers: await getHeaders(),
      params,
    },
  );
  return response.data;
};

export const getStoreProductById = async (
  productId: string,
  params: { hash: string; deliveryType?: string },
) => {
  const response = await axios.get<SearchProduct>(
    `${baseUrl}/v1/products/${productId}`,
    {
      headers: await getHeaders(),
      params,
    },
  );
  return response.data;
};

export const getStoreProductsFacets = async (
  params: Omit<GetSearchProductsQuery, 'deliveryType'> & {
    deliveryType?: string;
  },
) => {
  const response = await axios.get<GetSearchProductsFacetsResponse>(
    `${baseUrl}/v1/products/facets`,
    {
      headers: await getHeaders(),
      params,
    },
  );
  return response.data;
};

export const searchStoreProducts = async (
  params: Omit<SearchSearchProductsQuery, 'deliveryType'> & {
    deliveryType?: string;
  },
) => {
  const response = await axios.get<SearchSearchProductsResponse>(
    `${baseUrl}/v1/products/search`,
    {
      headers: await getHeaders(),
      params,
    },
  );
  return response.data;
};

export const getStoreProductsByIds = async (data: {
  hash: string;
  deliveryType?: string;
  ids: string[];
}) => {
  const response = await axios.request<GetSearchProductsResponse>({
    url: `${baseUrl}/v1/products/ids`,
    method: 'POST',
    headers: await getHeaders(),
    data,
  });
  return response.data;
};
